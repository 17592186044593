export default {
  countries: {
    now: 'Tu es en',
    change: 'Changer',
    name: {
      ES: 'Espagne',
      FR: 'France',
      DE: 'Allemagne',
      IT: 'Italie',
      UK: 'Grande Bretagne',
      MX: 'Mexique',
      PT: 'Portugal',
      EUR: 'Europe',
      AME: 'Amérique'
    }
  },
  global: {
    timeAgoMinutes: 'il y a {minutes} min',
    timeAgoHours: 'il y a {hours} h',
    timeAgoHoursMinutes: 'il y a {hours} h et {minutes} min',
    timeAgoDay: 'il y a 1 jour',
    timeAgoDays: 'il y a {days} jours'
  },
  pages: {
    home: 'Accueil',
    leaf: 'Fiche',
    list: 'Liste',
    contacts: 'Contacts'
  },
  components: {
    ProductDescription: {
      readMore: 'En savoir plus'
    },
    SliderCardFinder: {
      most_find: 'Le plus recherché :'
    }
  },
  product_types: {
    1: 'Chaussures de running',
    45: 'Montres de sport',
    71: 'Collants de running',
    72: 'Suppléments de sport',
    73: "Bracelets d'activité",
    74: 'Smartwatch',
    75: 'Compteur vélos',
    76: 'Écouteurs de sport',
    84: 'Chaussures de randonnée',
    102: 'Lunettes de soleil',
    105: 'Machines de fitness'
  },
  sections: {
    4: 'News Running',
    5: 'Entraînement running',
    6: 'Nutrition',
    7: 'Blessures sportives',
    15: 'Le blog mizuno de Raquel',
    31: 'Athlètes sketchers',
    32: 'Blogger Running fashion',
    45: 'Runnea podcast',
    58: 'Marathon de New York',
    63: 'Les Mamans runners',
    71: 'Outdoor',
    73: 'Équipe féminine Runnea',
    74: 'Électronique'
  },
  leaf: {
    features: {
      title: 'Caractéristiques',
      list: {
        gender: 'Genre',
        cushioning: 'Amorti',
        constitution: 'Constitution',
        footprint: 'Foulée',
        surface: 'Surface',
        use: 'Utilisation',
        beat: 'Rythme',
        foot: 'Pied',
        support: 'Appui',
        distance: 'Distance',
        weight: 'Poids',
        weightf: 'Poids Femme',
        weightm: 'Poids Homme',
        drop: 'Drop',
        droptie: 'Drop',
        colour: 'Couleur',
        options: 'Options',
        sensors: 'Capteurs',
        connections: 'Connections',
        autonomy: 'Autonomie (Mode GPS)',
        technology: 'Technologie',
        impermeability: 'imperméabilité',
        sole: 'Semelle',
        fabric: 'Tissu',
        sustainability: 'Eco responsabilité',
        year: 'Année'
      },
      values: {
        man: 'Homme',
        woman: 'Femme',
        low: 'Faible',
        medium: 'Moyen',
        high: 'Haut',
        short: 'Court',
        light: 'Mince',
        strong: 'Fort',
        neutral: 'Neutre',
        supinator: 'Supinateur',
        pronator: 'Pronateur',
        asphalt: 'Asphalte',
        trail: 'Trail',
        training: 'Entraînement',
        competition: 'Compétition',
        mixed: 'Divers',
        dig: 'Creux',
        flat: 'Plat',
        normal: 'Normal',
        heel: 'Talon',
        maraton: 'Marathon',
        'carbon-plate': 'Plaque de carbone',
        'half-maraton': 'Demi-marathon',
        '10k': '10K',
        fitness: 'Fitness',
        running: 'Running',
        running_shoes: 'Chaussures de Running',
        cycling: 'Cyclisme',
        swimming: 'Natation/plongée',
        outdoor: 'Outdoor',
        triathlon: 'Triathlon',
        crossfit: 'Crossfit',
        trekking: 'Randonnèe',
        gps: 'GPS',
        tactile: 'Écran tactile',
        color_screen: 'Écran couleur',
        maps: 'Cartes',
        pulse: 'Pouls du poignet',
        band: 'Ceinture cardio',
        music: 'Musique sans smartphone',
        spotify: 'Spotify',
        strava: 'Strava',
        altimeter: 'Altimètre',
        thermometer: 'Thermomètre',
        tensiometer: 'Tensiomètre',
        oximeter: 'Oxymètre',
        barometer: 'Baromètre',
        bluetooth: 'Bluetooth',
        wifi: 'WI-FI',
        nfc: 'NFC',
        fm: 'Radio FM',
        sim: 'Carte SIM',
        '1-12': '1-12h',
        '13-24': '13-24h',
        '25-36': '25-36h',
        '37-48': '37-48h',
        48: '48h et plus',
        yes: 'Oui',
        no: 'Non',
        'dry-line': 'Dry-line',
        'gore-tex': 'Gore-tex',
        'omni-tech': 'Omni-tech',
        outdry: 'Outdry',
        contagrip: 'Contagrip',
        continental: 'Continental',
        frixion: 'Frixion',
        michelin: 'Michelin',
        vibram: 'Vibram',
        tiptoe: 'Pointe des pieds',
        vegans: 'Vegan',
        'recycled-material': 'Matériel recyclé',
        co2: 'Reduction des emisions CO2',
        yellow: 'Jaune',
        blue: 'Bleu',
        white: 'Blanche',
        grey: 'Gris',
        brown: 'Marron',
        purple: 'Violette',
        orange: 'Orange',
        black: 'Noir',
        red: 'Rouge',
        pink: 'Rose',
        green: 'Verte'
      }
    },
    performances: {
      title: 'Performances',
      lightness: 'Légèreté',
      cushioning: 'Amorti',
      flexibility: 'Flexibilité',
      responsive: 'Réponse',
      stability: 'Stabilité',
      grip: 'Accroche',
      durability: 'Durabilité',
      fit: 'Ajustement',
      comfort: 'Commodité',
      quality: 'Qualité'
    }
  },
  list: {
    filters: {
      title: 'Filtres',
      brand: 'Marque',
      gender: 'Genre',
      price: 'Prix',
      drop: 'Drop',
      droptie: 'Drop',
      cushioning: 'Amorti',
      constitution: 'Constitution',
      footprint: 'Foulée',
      surface: 'Surface',
      use: 'Utilisation',
      beat: 'Rythme',
      foot: 'Pied',
      support: 'Appui',
      distance: 'Distance',
      sustainability: 'Eco responsabilité',
      size: 'Tailles',
      technology: 'Technologie',
      fabric: 'Tissu',
      store: 'Boutique',
      colour: 'Couleur',
      impermeability: 'Imperméablité',
      sole: 'Semelle',
      options: 'Options',
      type: 'Type',
      category: 'Catégorie',
      lens_category: 'Catégorie de lentille',
      lens_type: 'Types de lentilles',
      filter: 'Les filtres',
      province: 'Département',
      region: 'Région',
      country: 'Pays',
      year: 'Année',
      month: 'Mois',
      sensors: 'Capteurs',
      connections: 'Connections',
      autonomy: 'Autonomie (mode GPS)'
    },
    filtersValues: {
      man: 'Homme',
      woman: 'Femme',
      kid: 'Enfant',
      low: 'Faible',
      medium: 'Moyen',
      high: 'Haut',
      mediumhigh: 'Moyen haut',
      'carbon-plate': 'Plaque de carbone',
      light: 'Mince',
      soft: 'soft',
      strong: 'Fort',
      asphalt: 'Asphalte',
      trail: 'Trail',
      track: 'Piste',
      training: 'Entraînement',
      competition: 'Compétition',
      mixed: 'Divers',
      dig: 'Creux',
      flat: 'Plat',
      normal: 'Normal',
      tiptoe: 'Pointe des pieds',
      heel: 'Talon',
      pronator: 'Pronateur',
      neutral: 'Neutre',
      supinator: 'Supinateur',
      short: 'Courtes distances',
      maraton: 'Marathon',
      'half-maraton': 'Demi-marathon',
      '10k': '10K'
    }
  },
  product_sorting: [
    { value: 'recommended_desc', text: 'pertinence' },
    { value: 'date_desc', text: 'nouveautés' },
    { value: 'price_asc', text: 'du moins cher au plus cher' },
    { value: 'price_desc', text: 'du plus cher au plus cher' },
    { value: 'users_desc', text: 'évaluations des utilisateurs' },
    { value: 'review_desc', text: 'évaluation du spécialiste' },
    { value: 'discount_desc', text: 'plus de économies' }
  ],
  price_sorting: [
    { value: 'date_desc', text: 'nouveautés' },
    { value: 'price_asc', text: 'du moins cher au plus cher' },
    { value: 'price_desc', text: 'du plus cher au plus cher' }
  ],
  author: {
    written: 'a écrit',
    of: 'de',
    reviews: 'tests',
    articles: 'articles',
    by: 'Par',
    Twitter: 'Vous pouvez me suivre via Twitter',
    Instagram: 'Vous pouvez me suivre via Instagram'
  },
  cookies: {
    text: "Nous utilisons nos propres cookies pour conserver l'identification des utilisateurs enregistrés et pour pouvoir personnaliser les services et des cookies analytiques de tiers pour les statistiques sur les pages consultées, mais toujours de manière anonyme.",
    button: 'Politique de cookies',
    accept: "J'Accepte",
    deny: "je n'accepte pas"
  },
  article_list: {
    page_title: 'Magazine de running',
    meta_title: 'Articles de running - Vêtements et Chaussures de course',
    meta_description: "Articles de running publiés par des coureurs et l'équipe Runnea avec des conseils sur la nutrition, l'entraînement, les chaussures de running pour courir en pleine forme.",
    page_title_section: '{seo}',
    meta_title_section: 'Articles {seo}',
    meta_description_section: 'Articles de {seo} publiés par la rédaction de Runnea. Conseils de nutrition, entraînement, chaussures de running, trail et randonnée pour garder la forme.',
    page_title_author: 'Magazine de running de {seo}',
    meta_title_author: 'Articles de running {seo} - Vêtements et chaussures pour courir',
    meta_description_author: "Articles de running de {seo} avec des conseils sur la nutrition, l'entraînement, les chaussures de running pour courir en pleine forme.",
    page_title_text: "Magazine de running '{seo}'",
    meta_title_text: "Articles de running '{seo}' - Vêtements et chaussures pour courir",
    meta_title_tag_section: '{seo} - Vêtements et chaussures pour courir',
    comparator: 'Comparateur',
    nutrition: 'Nutrition',
    running_training: 'Entraînement running',
    sports_injuries: 'Blessures de sport',
    running_news: 'News Running',
    podcast: 'Podcast',
    outdoor: 'Outdoor',
    electronics: 'Électronique',
    magazine: 'Magazine'
  },
  article_page: {
    magazine: 'RUNNEA Magazine',
    articles: 'Articles',
    categories: 'Catégories',
    share: 'Partagez',
    follow: 'Suivez-nous',
    comments: 'Comentaires',
    related_articles: 'Articles associés',
    leave_comment: 'Laissez votre commentaire',
    registered: 'Vous devez être enregistré pour pouvoir faire un commentaire',
    register: "S'enregister",
    post: 'Publier',
    notifications: "Voulez-vous recevoir des notifications lorsqu'un utilisateur participe à ce chat ?",
    subscribe: 'Abonnez-vous',
    reportage: 'Reportage',
    copy_to_clipboard: "Copier l'URL dans votre Presse-papier",
    copied_to_clipboard: 'URL copiée dans votre Presse-papier',
    posted_on: 'Publié le',
    read_more_category: "Lire plus d'actualités sur : "
  },
  author_list: {
    page_title: 'Spécialistes en running',
    meta_title: 'Spécialistes en running, nutrition, entraînements et testeurs de chaussures de course',
    meta_description: "Spécialistes en running, nutrition , entraînement, chaussures de running pour courir en pleine forme et vous aider à choisir la chaussure de running qui répond le mieux à vos caractéristiques tout en l'achetant au meilleur prix.",
    page_title_articles: "Auteurs d'articles sur le running",
    meta_title_articles: 'Spécialistes en running, nutrition, entraînements et testeurs de chaussures de course',
    meta_description_articles: "Spécialistes en running, nutrition , entraînement, chaussures de running pour courir en pleine forme et vous aider à choisir la chaussure de running qui répond le mieux à vos caractéristiques tout en l'achetant au meilleur prix.",
    page_title_reviews: 'Testeurs de {seo}',
    meta_title_reviews: 'Testeurs de {seo}',
    meta_description_reviews: "Spécialistes en running, nutrition , entraînement, chaussures de running pour courir en pleine forme et vous aider à choisir la chaussure de running qui répond le mieux à vos caractéristiques tout en l'achetant au meilleur prix.",
    authors: 'auteurs.',
    showing_from: 'Affichant du',
    showing_to: 'au'
  },
  author_page: {
    magazine: 'Magazine de running de',
    articles: 'articles'
  },
  brand_list: {
    brands: 'Marques',
    title: 'Marques de produits de running | Runnea',
    description: 'Toutes les marques de produits de running dans notre catalogue pour vous aider à choisir la meilleure chaussure de running',
    marks: 'Marques de produits de running'
  },
  store_page: {
    brands: 'Marques principales',
    categories: 'Catégories de produits',
    since: 'Magasin partenaire de Runnea depuis ',
    reviews: 'Avis',
    best_sold: 'Les plus vendus de'
  },
  calculator: {
    page_title: 'Calculateur de temps',
    configure_sessions: "Configurez vos séances d'entraînement et nous calculerons la somme de tous les intervalles, les temps intermédiaires après chaque intervalle et les temps intermédiaires par kilomètre. Vous pouvez également imprimer le résultat ou l'enregistrer afin de le consulter à tout moment.",
    how_works: 'Comment cela fonctionne',
    fill_two: 'Remplissez deux des trois champs',
    of_each_interval: 'de chaque intervalle et nous nous chargerons de calculer le troisième.',
    insert_up: "Insérez jusqu'à 20 intervalles.",
    added_intervals: 'Les intervalles ajoutés peuvent être classés ou supprimés.',
    can_also: "Vous pouvez également insérer un groupe d'intervalles",
    to_which: 'auquel vous pourrez attribuer un nombre de répétitions. Au sein du groupe, les intervalles peuvent être classés ou supprimés.',
    save_your: "Enregistrez vos séances d'entraînement et vous pourrez y accéder quand vous le souhaitez.",
    to_save: 'Pour sauvegarder les séances, vous devez être enregistré.',
    interval: 'Intervalle',
    distance: 'Distance',
    acronym_meters: '(Mts)',
    rhythm: 'Rythme',
    acronym_rhythm: '(Min/Km)',
    time: 'Tiemps',
    actions: 'Actions',
    raise_position: 'Position élevée',
    lower_position: 'Position plus basse',
    remove: 'Éliminer',
    new_interval: 'nouvel intervalle de groupe',
    total: 'Total',
    see_hide: 'Voir/masquer les partielles',
    print: 'Imprimer',
    save: 'Sauvegarder',
    modify: 'Modifier',
    group: 'Groupe',
    delete_all: 'Effacer tout',
    calculate: 'Calculer',
    calculate_training: "Calculez vos temps d'entraînement",
    calculate_marathon: 'Calculez vos temps pendant un marathon',
    calculate_half_marathon: 'Calculez vos temps dans un demi-marathon',
    calculate_ten: 'Calculez vos temps sur 10 km',
    most_search: 'Le plus recherché',
    adv: 'Adv.',
    banner: 'Banner',
    reps: 'Reps.',
    error_missing: "Certains intervalles n'ont pas les valeurs nécessaires",
    error_empty: 'VVider un champ pour pouvoir calculer à nouveau',
    error_not_valid: 'Ces valeurs ne sont pas valides, veuillez les corriger.',
    error_need: "Nous avons besoin d'un minimum de deux valeurs pour faire le calcul"
  },
  calculator_10K: {
    page_title: 'Calculateur de temps 10 Km',
    insert_rhythm: 'Insérez votre rythme et nous calculerons le temps que vous feriez dans les 10 Km ou insérez le temps que vous voulez faire et nous calculerons le rythme dont vous avez besoin pour faire les 10 Km.'
  },
  calculator_half_marathon: {
    page_title: 'Calculateur de temps Demi-Marathon',
    insert_rhythm: 'Insérez votre rythme et nous calculerons le temps que vous feriez dans un semi-marathon ou insérez le temps que vous voulez faire et nous calculerons le rythme dont vous avez besoin pour faire un demi-marathon.'
  },
  calculator_marathon: {
    page_title: 'Calculateur de temps Marathon',
    insert_rhythm: 'Insérez votre rythme et nous calculerons le temps que vous feriez dans un marathon ou insérez le temps que vous voulez faire et nous calculerons le rythme dont vous avez besoin pour faire un marathon.'
  },
  check_in: {
    checking_user: 'Nous vérifions votre identifiant'
  },
  comparator_page: {
    page_title: 'Comparateur de {seo}',
    meta_title: 'Comparateur de {seo}',
    meta_description: "Comparateur de {seo}, d'aide pour choisir {seo_the_best}.",
    in: 'Chez',
    add: 'Ajouter',
    best: 'Meilleur',
    price: 'prix',
    money_symbol: '€',
    reviews: 'Tests',
    queries: 'Questions',
    benefits: 'Avantages',
    characteristics: 'Caractéristiques'
  },
  comparison_page: {
    page_title: '{seo}',
    meta_title: 'Comparateur - {seo}',
    meta_description: 'Éléments pour comparer et choisir si acheter des {seo}, en regardant le comparateur en ligne sur un seul site web.',
    in: 'Chez',
    best: 'Meilleur',
    price: 'prix',
    money_symbol: '€',
    opinions: 'Avis',
    queries: 'Questions',
    benefits: 'Avantages',
    characteristics: 'Caractéristiques',
    soon: 'Prochainement'
  },
  discount_codes_list: {
    page_title: 'Bons de réduction dans les boutiques de  running {year}',
    meta_title: 'Des bons de réduction pour acheter vos chaussures de running moins chères',
    meta_description: 'Tous les bons de réduction disponibles dans les boutiques en ligne pour faire vos achats à moindre coût',
    go_to_shop: 'Aller dans la boutique',
    coupon: 'Code Promo',
    copy_code: 'Copier le code'
  },
  gallery_page: {
    review: 'Test',
    opinions: 'Avis',
    prices: 'Prix',
    queries_recommendations: 'Questions et recommandations',
    photos: 'Photos',
    news: 'Actualités'
  },
  index: {
    covers: 'Couvertures',
    user_profile: "Profil d'utilisateur",
    sheet: 'Fiche',
    sheet_of: 'Fiche de',
    product: 'Produit',
    products: 'Produits',
    prices: 'Prix',
    race: 'Course',
    marks: 'Marques',
    article: 'Article',
    articles: 'Articles',
    reviews: 'Tests',
    author: 'Auteur*',
    authors: 'Auteurs',
    cupons: 'Bons de Réduction',
    popular_race: 'Courses à pied',
    query: 'Question',
    queries: 'Questions',
    galery: 'Galerie',
    comparative: 'Comparateur',
    list: 'Listes',
    list_of: 'Liste de',
    filter_product: '(filtré par produit)',
    filter_author: '(filtré par auteur)',
    filter_two: '(filtré par auteur et produit)',
    conList: 'Articles, Auteurs, Questions et Galeries',
    calculator: 'Calculateur',
    calculators: 'Calculateurs',
    marathon: 'Marathon',
    half_marathon: 'Demi-Marathon',
    ten_k: '10 km',
    link: 'Liens',
    redirecting: 'Redirection vers les prix externes',
    components: 'Composants',
    crisis: '(Crisecréative),'
  },
  inquiry_list: {
    meta_title: '{seo} | Conseils Runnea',
    meta_description: '',
    query: 'question',
    inquiries: 'questions',
    inquiries_pl: 'questions',
    inquiries_types_todas: 'Toutes',
    inquiries_types_1: 'Ma chaussure idéale',
    showing_from: 'Affichant du',
    showing_to: 'au',
    find: 'Rechercher',
    quick_search: 'Recherche rapide',
    category: 'Catégorie',
    ask: 'Poser une',
    consultorio: 'Cabinet Runnea',
    make_a: 'Poser une',
    required_field: 'Champs obligatoire',
    receive_notifications: "Je veux recevoir des notifications lorsqu'un utilisateur participe à cette demande",
    consult: 'Poser une question',
    thanks: "Merci d'avoir soumis votre demande",
    error_try_again: "Une erreur s'est produite lors de l'envoi de la question. Réessayer plus tard.",
    write: 'Écrivez votre texte ici',
    search_advanced: 'Recherche avancée',
    search_keyword: 'Mot-clé'
  },
  price_list: {
    outlet: 'outlet',
    page_title: 'Outlet de {seo}',
    subpage_title: 'Soldes {year} de grandes réductions {seo}',
    meta_title: 'Outlet de {seo} - Offres pour acheter online et avis',
    meta_description: "Liste des prix de {seo}, avec des avis d'utilisateurs et des évaluations pour vous aider à choisir la meilleure chaussure de running pour les sportifs au meilleur prix.",
    in_value: 'sur {value}',
    of_value: 'de {value}',
    for_value: 'pour {value}',
    with_value: 'avec {value}',
    only_value: ' {value}',
    filter_value: '{filter} {value}',
    in_filter_value: 'sur {filter} {value}',
    of_filter_value: 'de {filter} {value}',
    for_filter_value: 'pour {filter} {value}',
    order_by: 'Trier par',
    show: 'Affichant',
    shops: 'Boutiques',
    lowcost: 'Pas cher',
    not_found: 'Les filtres que vous avez sélectionnés pour',
    not_found_extra: 'ne nous ont pas permis de trouver de résultats.',
    page: 'Page',
    prices_of_product_type: 'Prix des {type}',
    of: 'de'
  },
  product_list: {
    page_title: 'Produits {filter}',
    meta_title: 'Produits {filter} - Offres pour acheter online',
    search_title: 'Résultats pour ',
    search_product_title: 'Résultats des produits pour la recherche ',
    search_article_title: 'Articles contenant ',
    not_found: "Nous n'avons trouvé aucun produit ni article pour la recherche ",
    feedback_text: 'Vous ne trouvez pas ce que vous cherchez ?',
    feedback_action: 'Envoyez-nous vos suggestions',
    feedback_link: '/suggestions.html',
    show_more: 'Montre plus',
    meta_description: 'Liste de Produits de Running {filter}, avec les avis et les évaluations des runners pour choisir les meilleurs produits de running et de course à pied au meilleur prix',
    list: 'Liste de',
    show: 'Affichant',
    of: 'de',
    pag: 'Page',
    list_of: 'Liste de',
    filters: 'Filtres',
    brand: 'Marque',
    gender: 'Genre',
    price: 'Prix',
    drop: 'Drop',
    droptie: 'Drop',
    cushioning: 'Amorti',
    constitution: 'Constitution',
    footprint: 'Foulée',
    surface: 'Surface',
    use: 'Utilisation',
    beat: 'Rythme',
    foot: 'Pied',
    support: 'Appui',
    distance: 'Distance',
    size: 'Tailles',
    technology: 'Technologie',
    fabric: 'Tissu',
    store: 'Boutique',
    man: 'Homme',
    woman: 'Femme',
    kid: 'Enfant',
    low: 'Faible',
    medium: 'Moyen',
    high: 'Haut',
    mediumhigh: 'Moyen haut',
    'carbon-plate': 'Plaque de carbone',
    light: 'Mince',
    soft: 'Doux',
    strong: 'Fort',
    asphalt: 'Asphalte',
    trail: 'Trail',
    track: 'Piste',
    training: 'Entraînement',
    competition: 'Compétition',
    mixed: 'Divers',
    dig: 'Creux',
    flat: 'Plat',
    normal: 'Normal',
    tiptoe: 'Pointe des pieds',
    heel: 'Talon',
    pronator: 'Pronateur',
    neutral: 'Neutre',
    supinator: 'Supinateur',
    short: 'Distances courtes',
    maraton: 'Marathon',
    'half-maraton': 'Demi-marathon',
    '10k': '10K',
    in_value: 'sur {value}',
    of_value: 'de {value}',
    for_value: 'pour {value}',
    with_value: 'avec {value}',
    only_value: '{value}',
    filter_value: '{filter} {value}',
    in_filter_value: 'sur {filter} {value}',
    of_filter_value: 'de {filter} {value}',
    for_filter_value: 'pour {filter} {value}',
    order_by: 'Trier par',
    visit: 'Visitez notre',
    text: 'Texte',
    type: 'Type',
    outlet: 'outlet',
    showing_from: '(Affichant résultats du',
    to: 'au',
    new: 'Nouveau',
    products: 'Produits',
    the: 'les',
    most: 'plus',
    sold_out_fem: 'vendues',
    sold_out_masc: 'vendus',
    about: 'au sujet de',
    relevance: 'pertinence',
    module_products_opinion_female: '{products} les plus commentées par les utilisateurs',
    module_products_opinion_male: '{products} les plus commentées par les utilisateurs',
    module_reviews: 'Dernières reviews de {products}',
    top_seller: 'Les plus vendues',
    position: 'Position'
  },
  product_page: {
    compare: 'Comparer',
    comparer: 'Comparateur',
    show: 'Voir',
    show_prices: 'Voir tous les prix',
    show_prices_man: 'Voir tous les prix hommes',
    show_prices_woman: 'Voir tous les prix femmes',
    show_more_large: "Plus d'informations sur ce modèle",
    show_more: 'Voir plus',
    note: 'Note du',
    note_fem: 'Note de la',
    specialist: 'Spécialiste',
    community: 'Communauté',
    leaf: 'Fiche',
    review: 'Test',
    review_title: 'Test {product}',
    reviews: 'Tests',
    reviews_likes_s: '1 personne ont trouvé cela utile',
    reviews_likes_p: '{number} personnes ont trouvé cela utile',
    reviews_like: 'Utile',
    photos: 'Photos',
    opinions: 'Avis',
    opinions_of: 'Avis de',
    inquiries: 'Demandes de renseignements',
    inquiries_of: 'Consultations de',
    similars: 'Semblables',
    news: 'Actualités',
    news_of: 'Actualités de',
    prices: 'Prix',
    from: 'À partir de',
    in: 'Dans',
    shops: 'Boutiques',
    collect: 'Nous collectons des prix pour',
    more: 'Plus de',
    sellers: 'Vendeurs',
    save: 'Économisez un',
    save_and: "et jusqu'à un",
    save_to: "Économisez jusqu'à",
    releases: 'dans les nouveautés',
    rest: 'dans le reste des',
    size: 'Tailles',
    size_no: 'Vous ne savez pas votre taille',
    query_table: 'Consultez le guide des tailles de',
    username: "Prénom de l'utilisateur",
    user_querys: 'Questions des utilisateurs',
    doubts: 'Vous avez des doutes ?',
    query_expert: 'Consultez notre expert',
    compare_with: 'Comparer avec',
    compare_with_label: 'Compare ',
    with: 'avec',
    conclusion: 'Conclusion',
    average_rating: 'Évaluation moyenne',
    user_average_rating: "Note de l'utilisateur",
    user_rating: 'User rating',
    from_users: 'Des utilisateurs',
    lightness: 'Légèreté',
    balance: 'Stabilité',
    response: 'Réponse',
    flexibility: 'Flexibilité',
    damping: 'Amorti',
    grip: 'Prise',
    rate_product: 'Évaluez cette chaussure',
    rated: 'des utilisateurs ont évalué ce modèle de',
    find: 'Trouvez',
    displaying: 'Affichant',
    of: 'de',
    querys: 'Questions',
    querys_comments: 'Questions et commentaires',
    querys_comments_of: 'Demandes de renseignements et commentaires de',
    show_comments: 'Voir commentaires',
    make_query: 'Je souhaite poser une question',
    news_talk: 'les actualités parlent de cette chaussure',
    community_talk: 'Faites confiance à la communauté Runnea, nos utilisateurs parlent de',
    this: 'ces',
    this_masc: 'ce',
    features: 'Caractéristiques -',
    features_of: 'Caractéristiques des',
    features_and_opinions: 'test et avis',
    best_information: 'avec des informations pour choisir la meilleure ',
    buy_at: 'Acheter au meilleur prix',
    best_price: 'Meilleur prix',
    benefits: 'Avantages',
    photo_gallery: 'Galerie de photos',
    original_price: 'Prix original',
    gender: 'Genre',
    sizes: 'Tailles',
    choose_size: 'Sélectionnez votre taille',
    no_prices_soon: " Ces derniers jours, nous n'avons pas détecté de prix pour:",
    no_prices_soon_vars: "Au cours des derniers jours, nous n'avons trouvé aucun prix pour : {product} avec la taille {filtersSize}. Essayez de sélectionner une autre taille.",
    your_selection: 'Votre sélection',
    no_prices: 'Soyez les premiers à recevoir une alerte lorsque les prix seront disponibles.',
    photo_galery: 'Galerie de photos',
    lower_query: 'questions',
    lower_opinions: 'avis',
    min: 'Min:',
    max: 'Máx:',
    anonymous: 'Anonyme',
    no_comments: 'Sans commentaires',
    no_queries: 'Aucune question',
    no_opinions: 'aucun avis',
    be_first_opinion: 'Soyez le premier à laisser un avis',
    more_compared: 'les plus comparées',
    size_guide: 'Guide des tailles',
    tester: 'Testeur',
    testers: 'Nos testeurs vous disent ce qu\'ils en pensent',
    added_product: 'Le produit a été ajouté au comparateur',
    go_to_comparator: 'Aller au comparateur',
    see_more_prices: 'Voir plus de Prix',
    more_prices_text: 'Découvrez plus de prix',
    more_prices_of: 'pour',
    featured_prices: 'Meilleures offres',
    also_from: 'Autres produits de',
    characteristics: 'Caractéristiques de la',
    with_information: ', avec des informations pour choisir la meilleure',
    money_acronim: 'EUR',
    see: 'Ver',
    products_of: 'Produits de',
    related_to: 'associés à cette recherche',
    page: 'Page',
    lower_page: 'page',
    score_text: 'Note RUNNEA',
    video: 'Vidéo',
    watch_video: 'Voir la vidéo',
    runnea_score_title: 'Comment calculons-nous le RUNNEA SCORE?',
    runnea_score_info: "Il s'agit d'une note générée sur la base des caractéristiques du produit, de l'intérêt et des opinions des utilisateurs et du rapport qualité-prix.",
    ranking_text: '<strong>{product}</strong> est en <b>{position} me position</b> dans le classement des {type} les mieux notées.',
    ranking_view: 'Voir ranking',
    awards: 'Prix',
    pros: 'Avantages',
    cons: 'Inconvénients',
    question: 'Question',
    question_first_letter: 'Q',
    answer: 'Réponse',
    answer_first_letter: 'R',
    answers: 'Réponses',
    fan_title: 'Êtes-vous un fan de',
    fan_text: 'Voir tous les modèles de :',
    expert: 'Expert',
    need_know: "Ce qu'il faut savoir",
    runner_type: 'Type de coureur',
    ideal_for: 'Idéal pour',
    strengths: 'Points forts',
    rivals: 'Rivaux',
    weight: 'Poids'
  },
  product_prices: {
    page_title: '{seo}',
    meta_title: '{seo} | {precio_desde} | {fecha}{pagina}',
    meta_gender_title: '{seo} | {precio_desde} | {fecha}{pagina}',
    meta_description: 'Liste des prix de {seo}, avec offres pour achat en ligne',
    best_offer: 'La meilleure offre',
    showing: 'Affichant',
    showing_total_prices: 'Affichant {total} {seo}',
    users_favourites: 'Les favorites des Runneantes',
    compare_with: 'Comparer avec',
    find_more_products: 'Trouvez {type} {brand}',
    in_value: 'sur {value}',
    of_value: 'de {value}',
    for_value: 'pour {value}',
    with_value: 'avec {value}',
    only_value: '{value}',
    filter_value: '{filter} {value}',
    in_filter_value: 'sur {filter} {value}',
    of_filter_value: 'de {filter} {value}',
    for_filter_value: 'pour {filter} {value}',
    show_prices: 'Voir prix',
    alert: "Nous vous informerons lorsqu'elles seront disponibles",
    add_alert: 'Ajouter alerte',
    average_grade: 'Note moyenne des runners',
    favorites: 'Les favorites des runneantes',
    fill_position: 'Occupe le ranking',
    best_note: 'avec les meilleurs évaluations des utilisateurs',
    of: 'de',
    from: 'du',
    to: 'au',
    prices: 'Prix',
    order_by: 'Trier par',
    show_filters: 'Afficher les filtres',
    prices_of: 'Prix des chaussures de running',
    page: 'Page',
    lower_page: 'page',
    for_selected: 'pour les filtres sélectionnés',
    send: 'Envoyer',
    country_from: 'Nous rends-tu visite depuis le {country} ?',
    country_change: 'Choisissez votre géolocalisation'
  },
  race_list: {
    page_title: 'Courses à pied{seo}',
    meta_title: 'Courses à pied{seo}, Calendrier des meilleurs courses',
    meta_description: "Calendrier des courses à pied{seo}, des informations complètes sur les inscriptions, le parcours, le lieu et l'heure de départ.",
    showing: 'Affichant',
    showing_total_races_seo: 'Affichant {total} courses à pied{seo}',
    in_value: 'en {value}',
    of_value: 'de {value}',
    for_value: 'pour {value}',
    with_value: 'avec {value}',
    only_value: '{value}',
    filter_value: '{filter} {value}',
    in_filter_value: 'en {filter} {value}',
    of_filter_value: 'de {filter} {value}',
    for_filter_value: 'pour {filter} {value}',
    race_year: 'Running : Courses à pied en {year}',
    find: 'Recherchez votre course',
    not_found: 'Aucun contenu trouvé pour cette recherche',
    description: "Date, délai d'inscription, parcours et classements de",
    start: 'Départ',
    filters: {
      distance: 'Distance',
      surface: 'Surface',
      province: 'Province',
      country: 'Pays',
      month: 'Mois',
      year: 'année'
    }
  },
  race_page: {
    meta_title: '{seo}',
    meta_description: "Informations utiles pour courir en {seo} avec des détails sur l'inscription, le parcours, le lieu et l'heure de départ, les classements, les coureurs et les habitudes d'entraînement.",
    tagline: 'Date, inscription, parcours et informations générales',
    browser_title: 'Running: Courses à pied dans {year}',
    races_title: 'Courses à pied',
    score_general: 'Géneral',
    score_route: 'Parcours',
    score_organization: 'Organisation',
    score_ambience: 'Environnement',
    score_refreshments: 'Rafraîchissements',
    poster: 'Affiche',
    average_rating: 'Évaluation moyenne',
    from_users: 'Des utilisateurs',
    view_race_web: 'Voir web de la course',
    leaf: 'Fiche',
    inscriptions: 'Inscriptions',
    route: 'Parcours',
    forum: 'Forum',
    verify: 'Verifier',
    feature_date: 'Date',
    feature_place: 'Lieu',
    feature_province: 'Province',
    feature_country: 'Pays',
    feature_surface: 'Surface',
    feature_time: 'Heure de départ',
    feature_participants: 'Participants',
    feature_contact: 'Contact',
    feature_organizer: 'Organisateur',
    feature_distance: 'Distance',
    something: 'Quelque chose',
    heart_rate_monitors: 'Moniteurs de fréquence cardiaque',
    popular_races: 'Courses à pied',
    outlet: 'Outlet',
    description: 'Description',
    will_be: 'aura lieu le',
    in: 'dans',
    anyway_is_important: "Dans tous les cas, il est important que vous confirmiez la date sur le site web de l'Organisateur",
    as_organizers: "Dès que les organisateurs nous auront communiqué le parcours de cette édition ou que la période d'inscription sera ouverte, nous publierons les informations correspondantes, mais si vous avez des questions sur cette course, n'hésitez pas à nous les adresser à",
    of: 'de',
    or_consult: "ou consultez le site web de l'organisation de la course.",
    inscription: "Date d'inscriptions à la",
    if_you_want: "Si vous voulez savoir quand s'ouvre la période d'inscription de cette édition de la",
    be_aware: "restez à l'écoute de notre site web et de notre forum de la",
    because: 'parce que nous informerons de manière ponctuelle.',
    unfold_info: 'Au fur et à mesure que se dévoilent les détails du parcours de cette édition de la',
    will_inform: 'nous vous tiendrons informé.',
    questions_doubts: 'Si vous avez des questions ou des doutes sur la course, vous pouvez envoyer vos questions à notre forum de la',
    fill_form: 'Remplissez ce formulaire afin que nous puissions le valider',
    organizer: "Êtes-vous l'organisateur de la course?",
    verify_race: 'Vérifier la course'
  },
  redirecting: {
    redirecting: 'Nous vous redirigerons vers le site officiel',
    value_proposal: 'Chez RUNNEA, nous comparons les prix dans plus de 150 boutiques en ligne vérifiées pour vous offrir le meilleur résultat, sans frais supplémentaires pour vous.',
    coupon_advice: "Pour bénéficier du prix sur lequel vous avez cliqué, n'oubliez pas d'utiliser le code de réduction!",
    accept_button: "OK, c'est compris!"
  },
  review_list: {
    page_title: 'Tests des {seo}',
    meta_title: 'Tests des {seo}',
    meta_description: 'Tests des {seo} | Runnea, une aide pour choisir la meilleure chaussure de course.',
    reviews: 'tests | test | tests',
    showing_from: 'Affichant du',
    showing_to: 'au'
  },
  opinion_list: {
    page_title: 'Avis de {seo}',
    meta_title: 'Avis de {seo}',
    meta_description: 'Avis{seo} | Runnea, une aide pour choisir la meilleure chaussure de course.',
    opinions: 'avis | avis | avis',
    showing_from: 'Affichant du',
    showing_to: 'au'
  },
  login: {
    session: "S'identifier",
    welcome: 'Bienvenue Runneante',
    forget: "J'ai oublié mon mot de passe",
    send: 'Envoyer',
    back: 'Retour',
    genericerror: 'Erreur de connection',
    closeSession: 'Fermer la session',
    no_account: "Je n'ai pas de compte, je souhaite m'enregistrer",
    logout: 'Se déconnecter',
    login: 'Se connecter',
    register: 'Enregistrement',
    incorrect_email: 'E-mail incorrect',
    incorrect_password: 'Mot de passe incorrect',
    remmember_password: 'Se souvenir du mot de passe'
  },
  reset_password: {
    new: 'Nouveau mot de passe',
    repeat: 'Répéter mot de passe',
    send: 'Envoyer',
    reset_password: 'Entrez votre nouveau mot de passe pour accéder à votre compte',
    change: 'Changement de mot de passe',
    different_passwords: 'Les mots de passe sont différents',
    description: 'Le mot de passe doit contenir 8 caractères, être alphanumérique et contenir des majuscules',
    successfully_update: 'Vous avez changé votre mot de passe. Vous pouvez maintenant vous rendre sur la page pour vous connecter',
    unknown_error: 'Erreur inconnue',
    error: 'Erreur'
  },
  register: {
    username: "Nom d'utilisateur",
    email: 'E-mail',
    name: 'Prénom',
    sex: 'Sexe',
    man: 'Homme',
    woman: 'Femme',
    country: 'Pays',
    postalCode: 'Código postal',
    brand: 'Avez-vous une marque préférée?',
    size: 'Taille',
    send: 'Envoyer',
    facebook: 'Logeate avec Facebook',
    google: 'Logeate avec Google',
    password: 'Mot de passe',
    repeat_password: 'Répéter le mot de passe',
    Emailnotcorrect: "L'e-mail n'est pas correct",
    Passwordsdontmatch: 'Les mots de passe ne correspondent pas',
    LowercaseLetterRequired: 'Une lettre minuscule est requise.',
    UppercaseLetterRequired: 'Une lettre majuscule obligatoire.',
    '8Characters': '8 caractères minimum.',
    OneNumber: 'Un chiffre est requis.',
    PasswordIncorrect: "Le mot de passe n'est pas correct, vérifiez qu'il contient des minuscules, des majuscules, plus de 8 caractères et quelques chiffres.",
    registrate: "S'enregistrer",
    successfully_registered: 'Enregistré avec succès',
    not_exist_email: "L'e-mail n'existe pas",
    remember_ok: "Nous vous avons envoyé un e-mail à l'adresse {email}. Vérifiez votre boîte de réception pour récupérer votre mot de passe.",
    user_not_found: 'Utilisateur non existant',
    error_recovering: 'Erreur de récupération des données',
    email_exists: "L'e-mail existe déjà",
    email_not_found: "L'e-mail n'a pas été trouvé",
    missing_fields: 'Champs manquants à compléter',
    incorrect_format: 'Format incorrect'
  },
  verify: {
    name_surname: 'Nom et prénom',
    email: 'E-mail',
    tlf: 'Numéro de téléphone',
    days: 'Jours de la semaine où nous pouvons vous contacter',
    hour: 'Heures où nous pouvons vous contacter',
    send: 'Envoyer',
    monday: 'Lundi',
    tuesday: 'Mardi',
    wednesday: 'Mercredi',
    thursday: 'Jeudi',
    friday: 'Vendredi',
    extra: 'Informations complémentaires',
    emailnotcorrect: "L'e-mail n'est pas correct",
    verified: "Merci d'avoir rempli le formulaire, nous vous contacterons pour confirmer les informations.",
    not_verified: 'Désolé, quelque chose a mal tourné, réessayez plus tard.',
    missing_fields: 'Il y a des champs manquants à compléter',
    accept: 'Accepter la',
    acceptPol: 'politique de confidentialité',
    form_first: 'Vous êtes organisateur d’une course ',
    form_first_after_bold: '?',
    form_second: 'Grâce au processus de vérification vous allez pouvoir donner plus de visibilité gratuitement à votre événement.',
    form_third: 'Si vous êtes l’organisateur, ou vous faire partie de l’équipe qui organise, l’événement remplissez le formulaire ci-dessous, nous vous contacterons pour finaliser le processus de vérification.'
  },
  registration: {
    already_account: "J'ai déjà un compte, je veux me connecter",
    continue: 'M’INSCRIRE'
  },
  talk_page: {
    consultant: 'Cabinet'
  },
  user_page: {
    modify: 'Modifier la photo',
    save: 'Sauvegarder les changements',
    select: 'Sélectionner'
  },
  not_found_page: {
    pageNotFound: 'Page non trouvée',
    pageNotFoundMsg: "Oh là là, c’est presque plus embêtant qu’un petit caillou qui se glisse dans votre chaussure au milieu d’une course ou d’une super rando !\nNous sommes désolés, la page que vous cherchez n'a pas été trouvée.\nVous pouvez revenir en arrière ou retourner à la page d'accueil.",
    goHome: "Aller à la page d'accueil"
  },
  article_finder: {
    find_here: 'Chercher un article du magazine'
  },
  AuthorLeaf: {
    has_written: 'a écrit',
    num_reviews_of: '0 tests de | 1 test de | {count} tests de',
    of: 'de',
    reviews: 'tests',
    articles: 'articles'
  },
  AuthorMiniLeaf: {
    by: 'Par'
  },
  BrandCard: {
    size_chart: 'Guide des pointures',
    go_to_size_chart: 'Aller au guide des pointures'
  },
  BrandsProduct: {
    also: 'Autres produits de',
    from: 'À partir de',
    money: '€',
    find: 'Trouvez vos chaussures de running'
  },
  DiscountCodeCard: {
    see: 'Voir réduction',
    share: 'Partager',
    go_to_store: 'Aller à la boutique',
    copied: 'Copié',
    special_cupon: 'Coupon spécial',
    date_up_to: "Jusqu'au",
    read_more: 'En savoir plus'
  },
  ModuleProductFeatureCard: {
    our_selection: 'Notre sélection'
  },
  InquiryTitleCard: {
    other_queries: 'Autres questions sur le running'
  },
  ModuleCarousel: {
    new: 'Nouveauté !',
    upper_new: 'NOUVEAUTÉS',
    discover: 'Découvrez-les',
    exclusive: 'Exclusive',
    text_1: 'Les courses virtuelles arrivent Runnea Academy Runnea Academy Runnea Aca',
    text_2: 'Runnea vous présente la liste des meilleures courses virtuelles à'
  },
  ModuleChats: {
    title: 'Demandes de renseignements et commentaires de {content}',
    showing: 'Affichage de {start} requêtes sur {end} ',
    show_all: 'Voir {total} questions des utilisateurs',
    no_contents: 'Aucune question ou commentaire'
  },
  ModuleComments: {
    title: 'Commentaires de {content}',
    showing: 'Affichant {start} de {end} commentaires ',
    show_all: 'Voir {total} questions des utilisateurs',
    no_contents: 'Aucun commentaire'
  },
  ModuleForm: {
    title: 'Contactez',
    field: 'Le champs',
    is_required: 'est obligatoire',
    query_received: 'Nous avons reçu votre demande. Nous vous contacterons dans les 48 heures. Merci de nous avoir contactés runneante.',
    error_submitting: 'Erreur en envoyant le formulaire',
    go_to_form: 'Accéder au formulaire',
    confirmButton: 'Compris'
  },
  ModuleMenu: {
    running_shoes: 'Chaussures Running',
    trekking_shoes: 'Chaussures Trekking',
    trail_shoes: 'Chaussures Trail',
    treadmill: 'Tapis de course',
    pulsometer: 'Moniteurs de fréquence cardiaque',
    running_training: 'Entraînement Running',
    menu: 'Menu',
    login: 'Se connecter',
    logout: 'Logout',
    account: 'Mon compte',
    title_ra: 'Entraîneur Personnel en ligne Running et Trail Running',
    claim: 'Nous vous aidons à mieux courir !',
    categories: 'Toutes les catégories'
  },
  ModulePricesBargain: {
    new: 'Nouvelle bonne affaire',
    discount: 'de réduction',
    go: 'Voir cette bonne affaire',
    click: '1 clic',
    clicks: '{clicks} clics'
  },
  newChatButton: {
    description: "Si vous avez des questions sur un sujet spécifique, vous pouvez choisir un expert pour vous aider. Ou si vous préférez, vous pouvez poser une question à la communauté et demander à l'un de nos experts ou à d'autres utilisateurs d'y répondre.",
    options: 'Choisissez une option',
    your_inquiry: 'Votre question',
    your_inquiry_for: 'Votre question pour:',
    to_do: 'Faire',
    do: 'Faites',
    quest: 'Choisissez un thème et posez votre question.',
    inquiry: 'question | questions',
    inquiries: 'aucune question | une question | des questions',
    select_category: 'Sélectionnez une catégorie',
    inquiry_description: 'Description de la question',
    write_your_text: 'Écrivez votre texte ici',
    required_field: 'Champs obligatoire',
    receive_notifications:
      "Je souhaite recevoir des notifications lorsqu'un utilisateur participe à cette question.",
    not_logged_in: "Vous n'êtes pas connecté",
    please_login_msg: "Vous n'êtes pas connecté. Veuillez vous connecter",
    thanks: "Merci d'avoir soumis votre demande",
    try_again: "Une erreur s'est produite en envoyant la demande. Veuillez réessayer plus tard.",
    mentioned_shoes: 'Chaussures de running mentionnées ',
    new_inquiry: 'Nouvelle demande',
    new_inquiry_text: 'Si vous avez des doutes sur le matériel de course, nos experts peuvent vous aider.',
    modal: {
      title: 'Demandez à un expert',
      text: "Si vous avez des questions sur l'équipement de running, écrivez-nous et notre équipe d'experts vous aidera du mieux qu'elle peut."
    },
    rgpd1: 'Trataremos tus datos para tramitar tu consulta y responderte a la misma. Puedes ejercer tus derechos de acceso, rectificación, supresión, oposición, limitación del tratamiento y portabilidad escribiéndonos a <a href="mailto:contacto@runnea.com" rel="nofollow">contacto@runnea.com</a>. Más información en la <a href="https://www.runnea.com/politica-privacidad.html" target="_blank" rel="noopener noreferrer nofollow">Política de Privacidad</a>',
    rgpd: 'Nous utiliserons vos données pour traiter votre demande et vous répondre. Vous pouvez exercer vos droits d\'accès, de rectification, d\'effacement, d\'opposition, de limitation du traitement et de portabilité en nous écrivant à <a href="mailto:contacto@runnea.com" rel="nofollow">contacto@runnea.com</a>. Plus d\'informations dans la <a href="https://www.runnea.com/politica-privacidad.html" target="_blank" rel="noopener noreferrer nofollow"> politique de confidentialité.</a>'
  },
  newCommentButton: {
    reply: 'Répondre',
    inquiry: 'question | questions',
    write_your_text: 'Écrivez votre texte ici',
    required_field: 'Champs obligatoire',
    receive_notifications: "Je veux recevoir des notifications lorsqu'un utilisateur participe à cette question",
    not_logged_in: "Vous n'êtes pas connecté",
    please_login_msg: "Vous n'êtes pas connecté. Veuillez vous connecter",
    thanks: 'Merci pour avoir participé à la demande',
    try_again: "Une erreur s'est produite en envoyant le commentaire. Veuillez réessayer plus tard."
  },
  newOpinionButton: {
    new: 'Laissez-nous votre avis',
    not_logged_in: "Vous n'êtes pas connecté",
    please_login_msg: "Vous n'êtes pas connecté. Veuillez vous connecter",
    performance: 'Services',
    comment: 'Commentaire',
    opinion: 'Donnez votre avs',
    score: 'Score',
    write: 'Écrivez votre avis',
    thanks: "Un grand merci de la part de toute l'équipe de RUNNEA et de la part de toute la communauté de coureur de RUNNEA. Votre avis est précieux car il va nous aider à tous à mieux choisir. N'hésitez pas à nous en envoyer sur d'autres modèles.",
    next: 'Dans quelques heures vous verrez votre avis publié sur le web.',
    try_again: "Une erreur s'est produite en envoyant votre opinion. Veuillez réessayer plus tard."
  },
  newCommentModule: {
    leave_comment: 'Laissez votre commentaire',
    registered: 'Vous devez être enregistré pour laisser des commentaires',
    register: "S'enregistrer",
    placeholder: 'Écrivez votre opinion',
    required_field: 'Champs obligatoire',
    post: 'Publier',
    thanks_msg: 'Commentaire envoyé. Merci pour votre participation.',
    error_msg: "Une erreur s'est produite en envoyant le commentaire. Veuillez réessayer plus tard.",
    rgpd: ''
  },
  NewsLettersCard: {
    message: 'Découvrez toutes les dernières nouvelles sur le monde de la running et les offres sur les équipements sportifs.',
    highlight: 'Vous allez la rater?',
    remember: "N'oubliez pas d'accepter les termes et conditions",
    successful: 'Nous venons de vous enregistrer.',
    error: "Une erreur s'est produite, Runneante. Veuillez réessayer plus tard",
    error_try: "Une erreur s'est produite. Veuillez réessayer plus tard",
    required: 'Champs obligatoire',
    thanks: 'Merci'
  },
  ProductCardPrincipal: {
    see: 'Voir fiche',
    see_description: 'Voir toutes les caractéristiques'
  },
  ProductHeaderButtons: {
    alert_received: 'Très bien, votre alerte de prix a été mise en place, si nous détectons un prix correspondant à vos critères, nous vous enverrons une alerte.',
    error_submitting: "L'alerte n'a pas pu être créée. Veuillez réessayer.",
    compare: 'Comparer',
    alert: ' Je veux être informé si le prix baisse !',
    available: "Préviens-moi dès que c'est disponible",
    disable_alert: 'Alerte',
    disable_price_alert: "Désactiver l'alerte de prix",
    close: 'Fermer',
    our_comparator: 'Notre comparateur examine des millions de prix chaque jour. Configurez vos alertes de prix et si nous trouvons un prix qui correspond à vos critères, nous vous enverrons une alerte.',
    model: 'Alerte prix pour ',
    sure: 'Cliquez sur le bouton “Confirmer” pour désactiver cette alerte de prix',
    confirm: 'Confirmer',
    alert_deleted: "Vous avez supprimé l'alerte de prix que vous aviez configurée sur le modèle {marca} {producto}.",
    error_deleting_alert: "Nous n'avons pas pu supprimer l'alerte de prix que vous aviez configurée sur le modèle {marca} {producto}. Contactez-nous pour que nous l'éliminions.",
    new_version: 'Nouvelle version disponible',
    new_version_btn: 'Voir la nouvelle version',
    old_version: 'Le modèle actuel est :',
    old_version_btn: 'Voir la Fiche'
  },
  ProductFeedback: {
    chat_1: 'Bonjour{user}',
    chat_2: 'Que pouvez-vous nous dire sur {product}?',
    state_wish: 'Je les adore',
    state_have: "J'en ai une paire",
    state_dislike: 'Je ne les aime pas',
    votes: 'votes',
    response_wait: '...',
    response_ok: 'Merci de votre participation!',
    response_ko: 'ERROR'
  },
  ContentPremium: {
    title: 'Connectez-vous avec votre compte pour voir le contenu',
    description: 'Créez votre compte GRATUIT et profitez de tous nos contenus exclusifs',
    advantages_1: 'Accès illimité aux articles à diffusion restreinte',
    advantages_2: "News Letter d'information avec contenu personnalisé",
    advantages_3: "Accès ilimité au service d'alerte de prix"
  },
  SliderCardFinder: {
    find: 'Rechercher'
  },
  UserPasswordForm: {
    new_password: 'Entrez votre e-mail de Runnea et nous vous enverrons un nouveau mot de passe.'
  },
  UserRegisterForm: {
    continue_with: 'Continuez avec',
    or: 'o',
    start: 'Complétez quelques détails et commencez à runnear !',
    required: 'Champs obligatoire',
    access_data: "Vos données d'accès",
    incorrect_user: 'Identifiant incorrect',
    remmember_password: 'Se souvenir du mot de passe',
    congratulations: 'Félicitation !',
    welcome: 'Bienvenue dans la communauté Runnea',
    complete: 'Remplissez quelques détails et commencez à runnear !',
    no_account: "Je n'ai pas de compte",
    name: 'Nom',
    surname: 'Prénom',
    user: 'Utilisateur',
    email: 'E-mail',
    password: 'Mot de passe',
    tread_type: 'Type de foulée',
    dont_know: 'Je ne sais pas',
    pronator: 'Pronateur',
    neutral: 'Neutre',
    supinator: 'Supinateur',
    commercial_communications: 'Je souhaite être informé des promotions et nouveautés. Vous serez informé des promotions en cours, des nouveautés et actualités commerciales.',
    legal: 'En créant votre compte, vous acceptez <a href="https://www.runnea.fr/avis-legal.html" target="_blank" rel="noopener noreferrer" class="legal_notice">les termes et conditions</a> de Runnea.',
    rgpd: 'Nous traiterons vos données pour permettre votre inscription sur notre site web et pour vous fournir nos services. Vous pouvez exercer vos droits d\'accès, de rectification, d\'effacement, d\'opposition, de limitation du traitement et de portabilité en nous écrivant à <a href="mailto:contacto@runnea.com" rel="nofollow">contacto@runnea.com</a>. Plus d\'informations dans la politique de confidentialité <a href="https://www.runnea.fr/politique-confidentialite.html" target="_blank" rel="noopener noreferrer nofollow">Politique de confidentialité</a>.'
  },
  UserAuthForm: {
    wrong_password: 'Utilisateur ou mot de passe incorrect',
    no_email: "L'e-mail n'existe pas"
  },
  influencer_card: {
    reviews: 'Tests',
    last_review: 'Dernière test',
    best_review: 'Meilleure test',
    show_price: 'Afficher le prix'
  },
  inquiry_card: {
    comments: 'Voir commentaires',
    no_comments: 'Sans commentaires'
  },
  inquiry_finder: {
    find_text: 'Filtrer par texte',
    find_product: 'Filtrer par produit'
  },
  list_filters: {
    filters: 'Filtres',
    filter: 'Filtrer',
    return: 'Retour',
    delete: 'Supprimer',
    sizes: 'Talles',
    show: 'Afficher',
    results: 'Résultats',
    example: 'Ej. Nike',
    more_brands: 'Plus de marques'
  },
  list_pagination: {
    before: 'Précédente',
    next: 'Suivante',
    first_page: 'Première page',
    last_page: 'Dernière page'
  },
  price_card: {
    shipping_costs: 'Envoi',
    from_price: 'à partir de  {price} €',
    buy: 'Acheter',
    go_to_store_w_price: 'Aller sur la boutique de <i>{store}</i> et acheter à {price} €',
    free_shipping: 'Livraison gratuite',
    show_offer: 'Voir offre',
    till: "Jusqu'au",
    coupon: 'Coupon',
    coupon_no: 'Sans Code',
    man: 'Homme',
    woman: 'Femme',
    kid: 'Enfant',
    unisex: 'Homme - Femme'
  },
  product_card_price_opinion: {
    let_opinion: 'laissez-nous un avis',
    opinions: 'Avis',
    questions: 'Questions'
  },
  product_finder: {
    find_here: 'Rechercher ici'
  },
  brand_finder: {
    find_here: 'Filtrer par marque'
  },
  race_finder: {
    find_here: 'Rechercher un poste',
    search: 'Rechercher'
  },
  module_finder: {
    title: 'Chaussures de running',
    text1: 'Trouvez le meilleur prix parmi plus de 20.000 chaussures de running',
    text2: 'Nous comparons plus de 200.000 chaussures de running des plus grandes marques',
    placeholder: 'Recherche par marque ou modèle',
    need_help: "Besoin d'aide pour faire votre choix ?",
    just_ask: 'Demandez à nos spécialistes',
    where_start: 'Vous ne savez pas par où commencer ?',
    use_shoe_finder: 'Essayez notre outil de recommandation',
    search_by_brand: 'Recherche par marque'
  },
  footer: {
    about: 'À propos de nous',
    newsletter: 'Newsletter',
    publicity: 'Publicité',
    product_test: 'Test de produit',
    shops: 'Votre boutique et vos produits',
    products: 'Produits',
    contact: 'Contact',
    legal: 'Avis juridique',
    privacity: 'Politique de confidentialité',
    cookies: 'Politique des Cookies',
    work: 'Travaillez avec nous',
    who: 'Qui sommes-nous',
    legal_information: 'Information légale',
    license: '',
    other_webs: 'Autres webs de RUNNEA',
    copyright: 'RUNNEA Copyright',
    follow: 'Suivez-nous',
    union: 'UNION EUROPÉENNE',
    union_deep: 'Fonds européen de développement régional',
    union_mes: "Gembira Tech, S.L. dans le cadre du programme ICEX Next Export Initiation, a eu le soutien de l'ICEX et le cofinancement du fonds européen FEDER. L'objectif de ce soutien est de contribuer au développement international.",
    union_mode: "Une façon de faire l'Europe",
    disclaimer: "RUNNEA peut recevoir une commission du vendeur si vous achetez par le biais du lien d'affiliation."
  },
  product_header: {
    specialist: 'Note du spécialiste',
    community: 'Note de la communauté',
    leaf: 'Fiche',
    review: 'Tests',
    opinions: 'Avis',
    inquiries: 'Questions et recommandations'
  },
  user_auth_form: {
    facebook: 'Logeate avec Facebook',
    google: 'Logeate avec Google'
  },
  youtube: {
    suscribe: 'ABONNEZ-VOUS AU CANAL',
    review: 'Test vidéo'
  },
  app_finder: {
    example: 'Marque ou modèle...',
    popular: {
      title: 'Découvrez',
      list: {
        1: {
          title: 'Chaussures running femme',
          link: '/chaussures-running/modeles/toutes/pour-femme/'
        },
        2: {
          title: 'Chaussures running homme',
          link: '/chaussures-running/modeles/toutes/pour-homme/'
        },
        3: {
          title: 'Chaussures trail',
          link: '/chaussures-running/modeles/toutes/surface-montagne/'
        },
        4: {
          title: 'Chaussures randonnée',
          link: '/chaussures-randonnee/modeles/toutes/'
        },
        5: {
          title: 'Montre GPS',
          link: '/montres-gps-et-cardio/modeles/toutes/'
        }
      }
    },
    most_searched: {
      title: 'Les modèles les plus recherchés',
      list: {
        1: {
          title: 'Nike Pegasus 40',
          link: '/chaussures-running/nike/pegasus-40/1018020/',
          img: 'https://static.runnea.fr/images/202305/nike-pegasus-40-chaussures-running-400x400x90xX.jpg?1'
        },
        2: {
          title: 'ASICS Gel Nimbus 25',
          link: '/chaussures-running/gel-nimbus-25/1006077/',
          img: 'https://static.runnea.fr/images/202212/asics-gel-nimbus-25-chaussures-running-400x400x90xX.jpg?2'
        },
        3: {
          title: 'Adidas Ultraboost Light 23',
          link: '/chaussures-running/adidas/ultraboost-23/1006210/',
          img: 'https://static.runnea.com/images/202302/adidas-ultraboost-23-zapatillas-running-400x400x90xX.jpg?1'
        },
        4: {
          title: 'New Balance FuelCell SC Elite v3',
          link: '/chaussures-running/new-balance/fuelcell-sc-elite-v3/1004934/',
          img: 'https://static.runnea.fr/images/202302/new-balance-fuelcell-sc-elite-v3-chaussures-running-400x400x90xX.jpg?3'
        },
        5: {
          title: 'Garmin Fenix 7',
          link: '/montres-gps-et-cardio/garmin/fenix-7/17233/',
          img: 'https://static.runnea.com/images/202201/garmin-fenix7-ficha-caracteristicas-precios-200x200x80xX.jpg?1'
        }
      }
    }
  },
  consent_cookie: {
    consent_modal: {
      title: 'Nous utilisons des cookies pour vous offrir la meilleure expérience possible',
      description: 'Nous utilisons nos propres cookies pour assurer le fonctionnement de base du site web et des cookies tiers pour obtenir des données statistiques sur votre navigation et pour faire de la publicité pour des produits personnalisés. Pour plus d\'informations, vous pouvez consulter notre <a href="/politique-cookies.html">politique de cookies</a>, notre <a href="/avis-legal.html">avis juridique</a> et notre <a href="/politique-confidentialite.html">politique de confidentialité</a>, et les <a href="https://policies.google.com/privacy" target="_blanc">Règles de Confidentialité de Google</a>.',
      primary_btn: 'Accepter tous',
      secondary_btn_1: 'Gérer les cookies',
      secondary_btn_2: 'Refuser tous'
    },
    settings_modal: {
      title: 'Gérer les cookies',
      save_settings_btn: 'Sauvegarder la configuration',
      accept_all_btn: 'Accepter tous',
      reject_all_btn: 'Refuser tous',
      close_btn_label: 'Fermer',
      cookie_table_headers: {
        col1: 'Nom',
        col2: 'Domaine',
        col3: 'Temps',
        col4: 'Description'
      },
      blocks_1: {
        title: 'Utilisation des cookies',
        description: 'Nous utilisons des cookies propres pour assurer le fonctionnement de base et offrir la meilleure expérience et des cookies tiers pour obtenir des données statistiques de votre navigation et annoncer des produits personnalisés. Pour plus d\'informations, vous pouvez consulter notre <a href="/politique-cookies.html">politique de cookies</a>, notre <a href="/avis-legal.html">avis juridique</a> et notre <a href="/politique-confidentialite.html">politique de confidentialité</a>, et les <a href="https://policies.google.com/privacy" target="_blanc">Règles de Confidentialité de Google</a>.'
      },
      blocks_2: {
        title: 'Cookies strictement nécessaires',
        description: 'Ces cookies sont essentiels au bon fonctionnement du site web. Sans ces cookies, le site web ne fonctionnerait pas correctement.'
      },
      blocks_3: {
        title: 'Cookies fonctionnels',
        description: 'Ces cookies sont importants pour le bon fonctionnement du site web. Sans ces cookies, nous ne pouvons pas vous offrir la meilleure expérience.'
      },
      blocks_4: {
        title: "Cookies d'analyse et de performance",
        description: "Ces cookies nous permettent de mesurer de manière anonyme l'utilisation et les performances du site web."
      },
      blocks_5: {
        title: 'Cookies publicitaires et de marketing',
        description: "Ces cookies nous permettent d'annoncer des produits personnalisés à l'utilisateur."
      },
      cookies: {
        hash: {
          expiration: 'Session',
          description: "Stocke les informations de la page à partir de laquelle le processus de connexion et d'inscription est effectué lors de l'utilisation de la connexion avec les réseaux sociaux (Google ou Facebook)"
        },
        auth_strategy: {
          expiration: '1 mes',
          description: "Stocke les informations du système de connexion choisi par l'utilisateur (Local, Google ou Facebook)"
        },
        cc_runnea: {
          expiration: '1 año',
          description: "Stocke des informations sur les préférences de l'utilisateur en matière de cookies"
        },
        brander: {
          expiration: 'Entre 1 jour et 1 an',
          description: "Enregistre les informations relatives à l'affichage des couches informatives afin qu'elles ne soient pas affichées à nouveau"
        },
        ga: {
          expiration: '2 ans',
          description: 'Ces cookies sont utilisés par Google Analytics pour distinguer les utilisateurs uniques en leur attribuant un numéro généré de manière aléatoire'
        },
        gid: {
          expiration: '2 ans',
          description: "Ces cookies sont utilisés par Google Analytics pour générer un identifiant unique de l'utilisateur afin de compter combien de fois un utilisateur visite le site, ainsi que la date de la première et de la dernière visite"
        },
        fbp: {
          expiration: '3 mois',
          description: "Utilisé par Facebook pour fournir une gamme de produits publicitaires, tels que des offres en temps réel d'annonceurs externes"
        },
        gads: {
          expiration: '13 mois',
          description: "Associé au service DoubleClick for Publishers de Google, il a pour but de mesurer les interactions avec les annonces afin d'éviter qu'elles ne soient diffusées à plusieurs reprises"
        }
      }
    }
  },
  recommender: {
    seo: {
      title: 'Assistant pour vous aider à choisir la chaussure running, tail, rando faites pour vous | Shoe finder | RUNNEA',
      description: 'Dans la jungle des modèles et des marques, trouvez vos chaussures de running idéales, étape par étape avec notre guide.'
    },
    tabs: {
      start: {
        text: 'Bonjour coureur! Nous allons vous aider à trouver la meilleure chaussure pour vous.',
        button: 'Démarrage',
        results: 'Voir résultats',
        autor: 'Gorka Cabañas',
        specialist: 'Spécialiste des chaussures de course'
      },
      purpose: {
        title: 'Vous cherchez des chaussures de:',
        text: "Courir sur route n'est pas la même chose que courir ou marcher en montagne. Dites-nous quel type de chaussures vous recherchez.",
        options: {
          running: 'Sur route',
          trail: 'Trail'
        }
      },
      gender: {
        title: 'Qui va porter ces chaussures?',
        text: "Aujoud'hui les marques produisent des modèles spécifiques à chaque genre, autant en profiter!",
        options: {
          man: 'Un Homme',
          woman: 'Une Femme'
        }
      },
      size: {
        title: 'Dites-nous votre pointure:',
        text: 'Nous vous recommandons de choisir une demi-pointure de plus que celle de vos chaussures de ville.',
        options: {
          default: 'Choisissez la pointure'
        }
      },
      footprint: {
        title: 'Quelle est votre type de foulée?',
        text: "Connaitre votre type de foulée vous permetra de courir ou marcher de manière plus sûre, et d'éviter ainsi des blessures.",
        options: {
          pronator: 'Pronateur',
          neutral: 'Neutre',
          supinator: 'Supinateur',
          none: 'Je ne sais pas'
        }
      },
      constitution: {
        title: 'Quelle est votre silhouette?',
        text: "La corpulence a une influence décisive sur le choix des chaussures. Plus on est lourd, plus on a besoin d'amorti.",
        options: {
          light: 'Léger',
          medium: 'Poid moyen',
          strong: 'Athlétique',
          none: 'Je ne sais pas'
        }
      },
      cushioning: {
        title: "Quel type d'amorti recherchez-vous?",
        text: "Un amorti maximal aporte d'excellentes sensations de confort tandis que les chaussures minimalistes favorisent plus la performence et la vitesse.",
        options: {
          soft: 'Minimaliste',
          neutral: 'Intermédiaire',
          high: 'Maximal',
          none: 'Je ne sais pas'
        }
      },
      foot: {
        title: 'Quel est votre type de pied?',
        text: 'Connaître votre type de pied nous aide à sélectionner la chaussure la mieux adaptée à votre morphologie.',
        options: {
          normal: 'Normal',
          dig: 'Creux',
          flat: 'Plat',
          none: 'Je ne sais pas'
        }
      },
      use: {
        title: 'Dans quel contexte allez-vous ulitiser vos chaussures?',
        text: "L'idéal est d'avoir une chaussure pour chaque domaine d'utilisation. Si vous manquez de place dans vos placards, optez pour des chaussures mixtes.",
        options: {
          training: 'Entrainement',
          competition: 'Compétition',
          mixed: 'Mixte'
        }
      },
      distance: {
        title: 'Quelles distances allez-vous parcourir?',
        text: "Certaines chaussures sont plus adaptées à certaines distances, il est important d'être cohérent.",
        options: {
          short: 'Courtes distances',
          '10K': '10K',
          'half-maraton': 'Semi marathon',
          maraton: 'Marathon',
          'ultra-trail': 'Ultra trail'
        }
      },
      beat: {
        title: 'A quel rythme courez-vous habituellement?',
        text: "Plus on va lentement plus on a besoin d'amorti, tandis que les rythmes rapides nécessiteront plus de reactivité.",
        options: {
          high: 'Moins de 4:30 Min/Km',
          medium: 'Entre 4:30 et 5:30 Min/Km',
          low: 'Plus que 5:30 Min/Km',
          none: 'Je ne sais pas'
        }
      },
      brand: {
        title: 'Avez-vous une marque préférée?',
        text: "Être fidèle à une marque présente autant d'avantage que de diversifié faire de belles découvertes. À vous de voir.",
        options: {
          default: 'Choisissez la marque',
          none: "J'aime dé couvrir"
        }
      },
      price: {
        title: 'Quel est votre budget?',
        text: 'Choisiez un buget en accord avec vos ambitions sportives, RUNNEA va vous aider a dénicher les meilleures promos du moment.',
        options: {
          low: 'Moins de 60€',
          medium: 'Moins et 100€',
          high: 'Plus de 100€',
          none: 'Peut importe'
        }
      },
      result: {
        texts: {
          loading: 'Nous recherchons les chaussures qui correspondent le mieux aux caractéristiques que vous venez de configurer...',
          contents: 'Les chaussures qui vous conviennent le mieux sont...',
          void: "Nous n'avons pas trouvé de chaussures présentant toutes les caractéristiques que vous nous avez indiquées. Essayez notre moteur de recherche de chaussures de running:",
          more_results: 'Vous pouvez voir plus de résultats ici'
        },
        product: {
          index: 'Voir les caractéristiques',
          prices: 'Voir tous les prix'
        },
        buttons: {
          contents: "Voir d'autres options",
          void: 'Voir le reste des options'
        }
      }
    }
  },
  runningMusic: {
    imgCalienta: 'https://static.runnea.com/images/202309/calienta.jpg?1',
    imgInfoIntencidad: 'https://static.runnea.com/images/202309/info-intensidad.png?1',
    imgIntencidad: 'https://static.runnea.com/images/202309/intensidad.png?1',
    imgPop: 'https://static.runnea.com/images/202309/pop.png?1',
    imgRock: 'https://static.runnea.com/images/202309/rock.png?1',
    imgSession01: 'https://static.runnea.com/images/202309/sesion-01.png?1',
    imgSession02: 'https://static.runnea.com/images/202309/sesion-02.png?1',
    imgSession03: 'https://static.runnea.com/images/202309/sesion-03.png?1',
    imgTechno: 'https://static.runnea.com/images/202309/techno.png?1',
    imgUrban: 'https://static.runnea.com/images/202309/urban.png?1'
  },
  recomend: {
    title: 'Recommandation de chaussures',
    subTitle: 'réponse immédiate',
    descriptions: 'Til vous guide avec des étapes simples pour choisir votre chaussure.'
  }
}
